@import '/src/assets/scss/variables';
@import '/src/assets/scss/mixins/media-queries';

[class*='col-'].no-gutter {
  padding: 0;
}

.col-base {
  padding: calc($gutter / 2);
}

@mixin gridGenerator($breakpoint) {
  @for $column from 1 to $columns-number + 1 {
    .col-#{$breakpoint}-#{$column} {
      width: calc(100% * $column / $columns-number);
    }
  }

  @for $column from 1 to $columns-number + 1 {
    .col-#{$breakpoint}-offset-#{$column} {
      margin-left: calc(100% * $column / $columns-number);
    }
  }
}

@include gridGenerator('xs');

@include sm {
  @include gridGenerator('sm');
}

@include md {
  @include gridGenerator('md');
}

@include lg {
  @include gridGenerator('lg');
}

@include xl {
  @include gridGenerator('xl');
}
